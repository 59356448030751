export const projects = [
  {
    title: "GoVibe",
    id: 1,
    description: "App Concept",
    image: "/images/GoVibe_Cover.png",
    images: [
      "/images/GOVIBE.png",
      "/images/GoVibe_2.png",
      // "/images/GoVibe_Wireframes.png",
    ],
    about:
      "GoVibe streamlines the process of finding activities to do nearby. GoVibe allows the user to create an itinerary to share with friends, or add to a favorite list. I designed and developed the splash screen animation, navigation, UX/UI and ensured compliance with ADA guidelines. GoVibe was created with a team of 6 using React Native, CSS, Ruby on Rails and API's.",
    role: "Front-End Developer and UX/UI Designer",
    link: "https://www.youtube.com/watch?v=nXwXDha69TY",
    linkTitle: "GoVibe Trailer",
  },
  {
    title: "Grandad's Tech",
    id: 2,
    description: "E-Commerce Website",
    image: "/images/Grandad_cover.png",
    images: [
      "/images/Grandad_1.png",
      "/images/Grandad_2.png",
      "/images/Grandad_3.png",
      "/images/Grandad_Wireframes.png",
      "/images/Grandads_Models.png",
    ],
    about:
      "Collaborated with a team of 4 on design and development of an end-to-end e-commerce website using the PERN stack. Grandad's Tech sells quality nostalgic tech products, complete with an admin portal to create, edit and delete products. Responible for designing and developing the prooduct page and the UX/UI to ensure a smooth and visually appealing user experience.",
    role: "Fullstack Developer and UX/UI Designer",
    link: "https://graceshopper-ogjq.onrender.com/",
    linkTitle: "Deployed Website",
  },
  {
    title: "The Ranch",
    id: 3,
    description: "UX / UI Design Update",
    image: "/images/TheRanch_4.jpg",
    images: [
      "/images/TheRanch_1_nobg.png",
      "/images/TheRanch_2.png",
      "/images/TheRanch_4.jpg",
      "/images/TheRanch_5.png",
    ],
    about:
      "Analyzed and addressed user pain points of existing website to improve engagement and functionality. Updates included compact navigation, creation of 'About' and 'Visit' pages, popup to capture user info, and footer. Designed mockups in Figma, optimized for desktop and mobile devices. Communicated with developer and client to ensure expectations and deadlines were met.",
    role: "UX/UI Designer",
    link: "https://theranch.art/",
    linkTitle: "The Ranch Website",
  },
  {
    title: "Neighborly",
    id: 4,
    description: "UX Design Concept",
    image: "/images/neighborlyHome.jpg",
    images: [
      "/images/Neighborly_1.jpg",
      "/images/Neighborly_2.jpg",
      "/images/Neighborly_3.jpg",
      "/images/Neighborly_4.jpg",
    ],
    about:
      "Neighborly makes it easy for people to find and shop at local businesses and build community. Concept forumulated during UX certificate bootcamp - performed competitor research, user journey maps, user personas, wireframes and prototypes in Adobe XD. ",
    role: "UX/UI Designer",
    link: "https://xd.adobe.com/view/f2dd095d-9235-4424-84e3-b2df90550444-a78e/?fullscreen&hints=on",
    linkTitle: "View Prototype",
  },
  {
    title: "Seasons Bloom",
    id: 5,
    description: "UX Design Concept",
    image: "/images/seasonsBloom.png",
    images: ["/images/SB_1.png", "/images/SB_screens.png"],
    about:
      "Seasons Bloom strives to give the user a simple and enjoyable flower ordering experience, targeting the unsure occasional buyer as well as a refined, subscription based user. Concept forumulated during UX certificate bootcamp - performed competitor research, user journey maps, user personas, wireframes and prototypes in Figma.",
    role: "UX/UI Designer",
    link: "https://www.figma.com/proto/9LXTnyMtr1pvkdNoHrt9d9/FLOWERS?page-id=602%3A202&type=design&node-id=648-650&viewport=670%2C464%2C0.15&t=9hYEK14MAUpnw8Yj-1&scaling=scale-down&starting-point-node-id=648%3A650&mode=design",
    linkTitle: "View Prototype",
  },
  {
    title: "Matters of Space",
    id: 6,
    description: "Interior Design",
    image: "/images/Mos_Bonita2.jpg",
    images: [
      "/images/Mos_Bonita1.jpg",
      "/images/Mos_Bonita2.jpg",
      "/images/Mos_Bonita3.png",
      "/images/Mos_Bonita4.png",
      "/images/Mos_Bonita5.jpg",
      "/images/Mos_Bonita6.jpg",
      "/images/Mos_Bonita7.png",
      "/images/Mos_Bonita8.jpg",
      "/images/Mos_Bonita9.png",
      "/images/Mos_Oxy1.jpg",
      "/images/Mos_Oxy2.jpg",
      "/images/Mos_Oxy3.png",
      "/images/Mos_Lowry1.jpg",
      "/images/Mos_Lowry2.jpg",
    ],
    about:
      "Design and project managed of 3-5 residential projects concurrently, ensuring smooth communication between the woodshop team, vendors, contractors, and clients. Participated in all aspects of the process - sending purchase orders, client invoicing, material selection, and drafting of furniture plans, electrical plans, kitchen and bath elevations, and custom furniture details.",
    role: "Associate Interior Designer and Project Manager",
    link: "https://www.mattersofspace.com/",
    linkTitle: "Matters of Space Website",
  },
  {
    title: "Aberizk Store",
    id: 7,
    description: "Interior Design",
    image: "/images/Aberizk_2.jpeg",
    images: [
      "/images/Aberizk_2.jpeg",
      "/images/Aberizk_1.jpeg",
      "/images/Aberizk_3.jpeg",
      "/images/Aberizk_4.jpeg",
    ],
    about:
      "Remodeled a storage space into an vintage store in NYC- maximized display potential, user flow and adhered to ADA standards. Designed the storefront, custom register and clothing racks to reflect the owners' vision for their first brick and mortar store.",
    role: "Interior Designer",
    link: "https://aberizk.com/",
    linkTitle: "Aberizk Website",
  },
];
